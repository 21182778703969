import { Box, Text, Collapse, Button, VStack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import SectionTitle from '../../../components/Titles/SectionTitle';
import getPortfolioLines, { Portfolio } from '../../../api/calculators/getPortfolioLines';
import { ArcaContext } from '../../../context/ArcaContext.context';
import ProjectConfig from '../../../constants';
import DocumentDownload from '../../../components/Documents/DocumentDownload';

const PlanEmpleoUser: React.FC = () => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const { token } = useContext(ArcaContext);

	const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [activeIndices, setActiveIndices] = useState<number[]>([]);

	useEffect(() => {
		const fetchPortfolioLines = async () => {
			try {
				const response = await getPortfolioLines(token);
				setPortfolios(response.data);
			} catch (err) {
				setError('Failed to fetch portfolio lines');
			}
		};

		fetchPortfolioLines();
	}, [token]);

	const toggleCollapse = (index: number) => {
		if (activeIndices.includes(index)) {
			setActiveIndices(activeIndices.filter((i) => i !== index));
		} else {
			setActiveIndices([...activeIndices, index]);
		}
	};

	return (
		<div>
			<SectionTitle text={translate('user.employmentPlan.info')} />

			<Text>{translate('user.employmentPlan.detail')}</Text>

			{error && <Text color="red">{error}</Text>}

			{portfolios.map((portfolio, index) => (
				<Box key={portfolio.portfolioCode} mb="4">
					<Button
						onClick={() => toggleCollapse(index)}
						w="100%"
						bg={ProjectConfig.ARCA_COLORS.PINK_200}
						color="black"
						_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_300 }}>
						{portfolio.portfolioCode}
					</Button>
					<Collapse in={activeIndices.includes(index)} animateOpacity style={{ width: '100%' }}>
						<Box w="100%" p="5" mt="4" borderWidth="1px" borderRadius="md" shadow="md">
							<VStack spacing="4" align="stretch" w="100%">
								{/* Header row */}
								<Box
									display="flex"
									justifyContent="space-between"
									p="2"
									borderBottom="1px"
									borderColor="gray.200"
									bg="gray.100"
									w="100%">
									<Text w="20%">{translate('user.employmentPlan.etfTitle')}</Text>
									<Text w="10%">{translate('user.employmentPlan.type')}</Text>
									<Text w="15%">{translate('user.employmentPlan.index')}</Text>
									<Text w="10%">{translate('user.employmentPlan.currency')}</Text>
									<Text w="10%">{translate('user.employmentPlan.size')}</Text>
									<Text w="10%">{translate('user.employmentPlan.expenses')}</Text>
									<Text w="10%">{translate('user.employmentPlan.trackingError')}</Text>
									<Text w="10%">{translate('user.employmentPlan.weight')}</Text>
								</Box>
								{/* Data rows */}
								{portfolio.lines.map((line) => (
									<Box
										key={line.lineId}
										display="flex"
										justifyContent="space-between"
										p="2"
										borderBottom="1px"
										borderColor="gray.200"
										w="100%">
										<Text w="20%">{line.name}</Text>
										<Text w="10%">{line.assetClass}</Text>
										<Text w="15%">{line.indexName}</Text>
										<Text w="10%">{line.currency}</Text>
										<Text w="10%">{line.sizeMeur}</Text>
										<Text w="10%">{line.expenses}</Text>
										<Text w="10%">{line.trackingError}</Text>
										<Text w="10%">{line.weight}</Text>
									</Box>
								))}
							</VStack>
						</Box>
					</Collapse>
				</Box>
			))}

			<SectionTitle text={translate('user.employmentPlan.documents')} />

			<DocumentDownload
				documentName={translate('user.employmentPlan.proforma')}
				downloadLink="https://drive.google.com/file/d/1V8J-iOWnWCbX00xhzNu0QppIYUlITK77/view?usp=drive_link"
			/>

			<SectionTitle text={translate('admin.setBenefit.retreat')} />

			<Button
				color={ProjectConfig.ARCA_COLORS.BLACK}
				backgroundColor={ProjectConfig.ARCA_COLORS.PINK_200}
				_hover={{
					color: ProjectConfig.ARCA_COLORS.BLACK,
					background: ProjectConfig.ARCA_COLORS.PINK_300,
					fontWeight: 'bold',
					fontSize: '17px'
				}}>
				{translate('admin.setBenefit.retreatButtton')}
			</Button>
		</div>
	);
};

export default PlanEmpleoUser;
