import { Box, Card, CardBody, CardFooter, CardHeader, Heading, Link, Text } from '@chakra-ui/react';
import ProjectConfig from '../../../constants';
import InfoIconClick from '../infoIconClick';
import { useNavigate } from 'react-router';

interface BenUserCardProps {
	heading?: any;
	cardBody: any;
	infoIconVisible: boolean;
	infoIconText: string;
	linkText?: string;
	link?: string;
	width?: string;
	height?: string;
	minW?: string;
	cardBodyTextSize?: any;
	textSize?: string;
}

const BenUserCard: React.FC<BenUserCardProps> = ({
	heading,
	cardBody,
	infoIconVisible,
	infoIconText,
	linkText,
	link,
	width,
	height = '190px',
	minW = '200px',
	cardBodyTextSize = { base: '24px', sm: '26px', md: '28px', xl: '32px' },
	textSize
}: BenUserCardProps) => {
	const navigate = useNavigate();
	return (
		<Card
			display="flex"
			borderRadius="16px"
			minW={minW}
			height={height}
			backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
			width={width}>
			<CardHeader
				display="flex"
				alignItems="start"
				justifyContent="space-between"
				p="24px 24px 0px 24px">
				<Heading fontFamily={ProjectConfig.FONTS.INTER} fontSize="16px" fontWeight="400">
					{heading}
				</Heading>
				<Box fontSize="20px">
					<InfoIconClick isVisible={infoIconVisible} text={infoIconText} />
				</Box>
			</CardHeader>
			<CardBody
				fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
				fontWeight="900"
				display="flex"
				alignItems="flex-end"
				justifyContent="flex-start"
				p="0px 15px 25px 20px"
				ml="12px">
				<Text fontSize={textSize || cardBodyTextSize}>{cardBody}</Text>
			</CardBody>
			{link && linkText && (
				<CardFooter>{link && <Link onClick={() => navigate(link)}>{linkText}</Link>}</CardFooter>
			)}
		</Card>
	);
};

export default BenUserCard;
