import LoginForm from '../components/LoginForm';

const Login = ({ children }: any) => {
	return (
		<>
			<LoginForm />
		</>
	);
};

export default Login;
