import OnboardingUser from '../components/OnboardingUser';
import UserInvitation from '../components/UserInvitation';
import Dashboard from '../views/Dashboard';
import AdminContribuciones from '../views/DashboardTabs/Admin/AdminContribuciones';
import Login from '../views/Login';
import NotFoundPage from '../views/NotFoundPage';

interface ConfigRoutesI {
	path: string;
	component: React.ReactNode;
}

export const redirectRoutes: ConfigRoutesI[] = [
	{
		path: '/',
		component: <Login />
	}
];

export const publicRoutes: ConfigRoutesI[] = [
	{
		path: '*',
		component: <NotFoundPage />
	},
	{
		path: '/login',
		component: <Login />
	},
	{
		path: '/accept-user-invitation',
		component: <UserInvitation />
	}
];

export const privateRoutes: ConfigRoutesI[] = [
	{
		path: '*',
		component: <NotFoundPage />
	},
	{
		path: '/dashboard/:tab',
		component: <Dashboard />
	},
	{
		path: '/onboarding-user',
		component: <OnboardingUser />
	}
];
