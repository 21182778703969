import React, { useState, useContext, useEffect } from 'react';
import '../styles/LoginForm.css';
import login from '../api/login/login';
import { Button, useDisclosure } from '@chakra-ui/react';
import { ArcaContext } from '../context/ArcaContext.context';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import GenericModal from './ChakraUi/Modals/GenericModal';
import ButtonSpinner from './ChakraUi/Spinners/ButtonSpinner';
// import { getAnalytics, logEvent } from 'firebase/analytics';

// const analytics = getAnalytics();

const LoginForm = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { setToken, setCompanyId, setUserId, setUserAccountType, setLanguage } =
		useContext(ArcaContext);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const navigate = useNavigate();

	useEffect(() => {
		if (!isOpen) {
			setIsLoading(false);
		}
	}, [isOpen]);

	const handleLogin = async (event: React.FormEvent) => {
		event.preventDefault(); // Prevent the default form submission

		setIsLoading(true);

		try {
			const response = await login(email, password);

			const responseData = response.data;

			if (!response.success || !_.isNil(responseData.error)) {
				// logEvent(analytics, 'login_error');
				onOpen();
				// setIsLoading(false);
				return;
			}

			handleLoginSuccess(responseData);
			// logEvent(analytics, 'login_success');

			navigate('/dashboard/home');
		} catch (error) {
			console.error('Error al iniciar sesión:', error);
			onOpen();
		}
	};

	const handleLoginSuccess = (responseData: any) => {
		const { user, token } = responseData;
		if (user) {
			const { companyId, id: userId, accountType, language } = user;

			setCompanyId(companyId);
			localStorage.setItem('companyId', companyId);

			setUserId(userId);
			localStorage.setItem('userId', userId);

			setUserAccountType(accountType);
			localStorage.setItem('userAccountType', accountType);

			setLanguage(language);
			localStorage.setItem('language', language);
		}

		if (token) {
			localStorage.setItem('token', token);
			setToken(token);
		}
	};

	return (
		<div className="login-form">
			<div className="container">
				<img src="/imgs/LOGO_VERDE_2.svg" alt="Logo Arca" />
				<form onSubmit={handleLogin}>
					<input
						type="email"
						placeholder="Correo electrónico"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<input
						type="password"
						placeholder="Contraseña"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<p>¿Olvidaste tu contraseña?</p>
					<Button type="submit" colorScheme="gray">
						{isLoading ? <ButtonSpinner /> : 'Acceder'}
					</Button>
				</form>
			</div>
			<GenericModal
				isOpen={isOpen}
				onClose={onClose}
				message="Ocurrió un error durante el inicio de sesión. Por favor, verifica tus credenciales e intentalo de nuevo."
				isError={true}
				closeModalButton={true}
			/>
		</div>
	);
};

export default LoginForm;
